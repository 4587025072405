import React from "react"
import {graphql} from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import Loadable from "@loadable/component"
import Breadcrumbs from "../components/Common/Breadcrumbs"

const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const ProjectDetails = ({ data }) => {
  const { title, longDescription } = data.project

    const seoTitle = title
    const parent = [{
      name: "Portfolio",
        link: 'portfolio'
    }];

    const screenshotsRegex = /### Screenshots\n([\s\S]*?)(?:\n###|$)/g
    const screenshotsMatch = longDescription?.match(screenshotsRegex)
    const screenshots = screenshotsMatch?.map((match) => match.trim().replace(/^!\[.*\]\((.*)\)$/, '$1'))
    const regex = /\((.*?)\)/g;
    const srcLinks = [];

    let match = regex.exec(screenshots);

    while (match !== null) {
      srcLinks.push(match[1]);
      match = regex.exec(screenshots);
    }

  const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 3000,
    items: 1,
    animateOut: "fadeOut",
    navText : ["<",">"]
  }

  const extractContent = (data) => {
    const lines = data.split('\n');
    let result = '';

    for (let i = 0; i < lines.length; i++) {
      if (lines[i] === '### Screenshots') {
        break;
      }

      result += lines[i] + '\n';
    }

    return result;
  }

  const content = extractContent(longDescription);
    return (
      <>
        <Layout>
      <Seo title={seoTitle} />
      <Navbar />
      {/*<PageBanner pageTitle={title} parent={parent} />*/}
      <div className="ptb-80">
        <div className="container">
          <div className="section-title">
            <h1>{title}</h1>
            <div className="bar"/>
          </div>
          <div className="details-content portfolio-details-content">
            <Breadcrumbs pageTitle={title} parent={parent} />
            <ReactMarkdown>{content}</ReactMarkdown>
            <h3>Screenshots</h3>
            <OwlCarousel
              className="ml-feedback-slides owl-carousel owl-theme mt-5 w-100"
                {...options}
            >
              {srcLinks.map((e,index)=> {
                return (
                    <div className="single-ml-feedback-screenshot" key={index}>
                      <img
                          src={e}
                          alt="Project Image"
                      />
                    </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>

      <Footer />
        </Layout>
      </>
  )
}

export const query = graphql`
  query GetSingleProjectDetails($slug: String) {
    project: strapiProjects(slug: { eq: $slug }) {
      title
      longDescription
    }
  }
`

export default ProjectDetails
